.user-dashboard {
    display: flex;
    height: auto;
    background-color: #f2f2f2;
  }
  
  .sidebar {
    text-align: center;
    width: 200px;
    height: 100vh;
    background-color: #3498db;
    color: #fff;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar li {
    padding: 10px;
  }
  
  .sidebar a {
    color: #fff;
    text-decoration: none;
  }
  
  .sidebar a:hover {
    color: #f2f2f2;
    background-color: #3498db;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    padding-top: 40px;
  }
  
  input[type="file"] {
    margin-bottom: 10px;
  }
  
  .sidebar button {
    padding: 10px 10px;
    width: 150px;
    background-color: #fff;
    color: #3498db;
    border: none;
    cursor: pointer;
  }
  
  .sidebar button:hover {
    background-color: #fff;
  }
  .main-content .disabled{
    padding: 10px 20px;
    background-color: #807f7f;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .main-content .disabled:hover {
    background-color: #807f7f;
  }
  .main-content button{
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .main-content button:hover {
    background-color: #3498db;
  }
  
  h3 {
    color: #3498db;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  pre {
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .DocumentPreview {
    margin-bottom: 20px;
  }
  
  /* Customize the colors as desired */
  