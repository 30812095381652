
/*****************footer starts here*************/

.footersection{
	width: 100%;
	height: auto;
	padding: 50px 0 5px 0;
	background:#d5d5d5;
}
.footersection p{
	color:#000;
	font-family: sans-serif;
}
.footer2 .nav-link{
	font-size: 1rem;
	line-height: 1;
	font-weight: 600;
	color: #000 !important;
	text-transform: capitalize;
	transition: 0.3s ease;
	text-decoration: none;
}
.footer2 .nav-link:hover{
	transform: translateY(-3px);
	color: #000 !important;
}
.footer3{
	color:#000;
}
.social-div a{
	text-align: center;
	font-size: 2rem;
	margin: 10px;
	color: #000 !important;
	padding: 50px 20px 50px 0;
	transition: 0.3s ease;
}
.social-div a:hover{
	transform: translateY(-3px);
	color: #000 !important;
}

.footersection h3{
	text-transform: uppercase;
	color:#000;
	margin-bottom: 25px;
	font-size: 1.6rem!important;
	font-weight: 800;
}
.footline{
	height: 5px;
    margin: 30px 0px;
    text-align: center;
    width: 100%;
    background-color: #3498db;
}

@media (max-width:960px){
	.footersection h3{
		margin-top: 20px;
	}
}
/*****************footer ends here*************/



