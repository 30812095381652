.navbar{
	/* box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.1)!important; */
	padding: 20px;
	color: #000;
    /* background-color: #1A1A1A!important; */
    font-family: 'Ubuntu', sans-serif;
    z-index: 2;
	/* box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.1)!important; */
}
.navbar-brand{
    height: 60px;
    width: 150px;
}
.nav-link {
	font-size: 1.2rem;
	padding: 10px;
	margin: 0 10px;
	font-weight: bold;
	color: #000!important;
	border-bottom: 2px solid transparent!important;
	transition: 0.6s all;
	outline: none;
}
.nav-link:hover{
	color: #000!important;
	border-bottom: 2px solid #000;
}
.form-inline{
	margin-left: 100px;
}
.form-control:focus{
    outline: none !important;
    box-shadow: none;
}
.btnn{
    border:none!important;
    color: #000;
    background: transparent;
}
.btnn:hover{
    background:none!important;
    color: #000;
}

.button-container{
    margin-bottom: 20px;
}
.button-container button, .button-container button:hover, .button-container1, .button-container1:hover{
    padding: 10px 22px;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.3;
	color:#3498db;
	margin: 10px;
	text-align: center;
	/* background: linear-gradient(to right, #bf0fff, #3498db); */
    background: #fff;
    border: 2px solid #3498db;
	letter-spacing: 0.5px;
	transition: 0.3s all;
	border-radius: 5px;
}


/* .header-buttons a{
	padding: 10px 22px;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.3;
	color:var(--light-color);
	margin: 5px;
	text-align: center;
	background: linear-gradient(to right, var(--orange), var(--yellow));
	letter-spacing: 0.5px;
	transition: 0.3s all;
	border-radius: 5px;

}
.header-buttons a:hover{
	
	text-decoration: none;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.3)!important;
} */


@media (max-width: 960px){
	.navbar-nav{
		margin-left: 10px;
	}
	.navbar-brand{
		margin: 0;
		height: 50px;
		width: 130px;
	}
	.nav-item a{
	border-bottom: none;
	font-size: 1rem;
	}
	.nav-item a:hover{
		color: var(--medium-blue1)!important;
		border-bottom: none;
	}
	.header-buttons a{
		padding: 10px 10px;
		font-size: 0.9rem;
		font-weight: 600;
		line-height: 1;
		margin: 0;
	}
	.form-inline{
		margin: 10px;
	}
}