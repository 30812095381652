/* Main styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #fff;
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    color: #333;
    font-size: 32px;
    text-align: center;
    margin-top: 40px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .button-container button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-container button:hover {
    background-color: #45a049;
  }
  
  
  
  /* Login form styles */
  .login-form {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .login-form button:hover {
    background-color: #45a049;
  }
  
  /* Admin panel styles */
  .admin-panel {
    margin-top: 40px;
  }
  
  .admin-panel h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .admin-panel p {
    color: #777;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Upload document styles */
  .upload-doc {
    margin-top: 40px;
  }
  
  .upload-doc h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .upload-doc p {
    color: #777;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .upload-doc input[type="file"] {
    margin-bottom: 10px;
  }
  
  .upload-doc button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-doc button:hover {
    background-color: #45a049;
  }
  


  @import url('https://fonts.googleapis.com/css2?family=Edu+SA+Beginner&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

body { 
  font-family: 'Ubuntu', sans-serif;
}

.combsec{
    height: auto!important;
}
.theblank{
    height:115vh;
    width: 100%;
}
.navpad{
    padding-top: 100px;
}
.header-section{
	width: 100%;
	height: 75vh;
    position: relative;
    /* padding-top: 100px; */
	color: #000;
	text-align: center;
    
    /* background: linear-gradient(rgba(0, 0, 0, 0.0),rgba(0, 0, 0, 0.0 )), url(../../img/top-view-desk-concept-with-copy-space.jpg); */
    background-size:  100% 75vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.rightone1{
    margin-top: 2%;
    padding-right: 100px;
}
.leftone{
    margin-top: 0%;
}
.center-div h1{
	font-size: 3.6rem;
    font-weight: 400;
	color:#000;
	line-height: 75px;
    padding-bottom: 10px;
    font-family: 'Ubuntu', sans-serif;

}

.center-div h1 span{
	color:#3498db !important;
    font-size: 5rem;
    font-weight: 900;
}
.center-div h3{
    font-size: 1.7rem;
    color: #000;
    font-weight: 600;
    padding-top: 10px;
}
.center-div p{
	font-size: 1.3rem;
	color: #000;
	padding-bottom: 20px;
	margin-top: 10px;
	margin-bottom: 25px;
    font-weight: 500;
}

.header-buttons a{
	padding: 12px 25px;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.4;
	color:#000;
	margin: 0 5px;
	text-align: center;
	border: 2px solid #000;
	letter-spacing: 0.5px;
	transition: 0.3s all;
	border-radius: 5px;

}

/* .header-buttons a:hover{
	
	border: 1px solid var(--purple-light-color);
	color: #fff;
	background: var(--purple-light-color);
	text-decoration: none;
    border: 2px solid var(--purple-light-color);
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.3)!important;
} */

.blackbox1, .blackbox2, .blackbox3{
    /* border-radius: 3px; */
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3)!important;
    height: 400px;
    margin: 40px 0 0 20px;
    text-align: center;
    width: 15px;
    background-color: #5cecf0;
}

.blackbox2{
    margin-top: 80px;
}
.blackbox3{
    margin-top: 120px;
}
/*header section*/	
/*****************header section  queries*************/

/*****************header ends here*************/



@media (max-width: 860px ){
    .theblank{
        height: 160vh;
    }
    .leftone{
        padding-left: 30px;
        margin-top: 17%;
    }
    
    .center-div h1{
        font-size: 2.3rem;
        line-height: 40px;
    }
    .center-div h1 span{
        font-size: 2.9rem;
        line-height: 45px;
    }
    .blackbox1, .blackbox2, .blackbox3{
        height: 250px;
        width: 12px;
    }
    /* .center-div h3{
        font-size: 1.5rem;
        line-height: 30px;
    }
    .center-div p{
        font-size: 1rem;
        line-height: 5px;
    }
    .rightone img{
        height: 300px;
        width: 300px;
        margin-left:30px;
    } */
}

/*****************header ends here*************/


/*****************our workflow starts here*************/

.work_flow{
    padding: 50px 0;
    height: auto;
    width: 100%;
    background: #fff;
    /* box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.1)!important; */
}
.work_flow .work_head{
    text-align: center;
}
.work_flow h5{
    font-weight: 600;
    color: #495057;
}
.work_flow h1{
    font-weight: 700;
    color: #03071e;
    padding-bottom: 50px ;
    
}
.work_flow h4{
    font-weight: 500;
    color: #03071e;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #3498db;
    padding-top: 15px;
    text-align: center;
    box-shadow: 10px 0px 10px 4px rgba(0,0,0,0.1)!important;
    transition: 0.5s ease all;
    margin-left: auto;
    margin-right: auto;
}
.work_flow h4:hover{
    color: #3498db;
    background: #fff;
    border: 2px solid #3498db;
}
.work_flow h3{
    text-align: center;
    padding: 20px 0px 50px 0px;
    font-weight: 600;
    color: #03071e;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width:844px){
    .work_flow{
        text-align: center;
        padding: 50px 0 ;
    }
    .work_flow h3{
        padding: 10px 20px 20px 0;
    }
}


/*****************our workflow starts here*************/
