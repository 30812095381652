.admin-container {
    display: flex!important;
  }
  
  .sidebar {
    width:  200px;
    background-color: #3498db;
    padding: 10px;
    padding-top: 20px;
    height: 100vh;
  }
  
 
  
  .user-item {
    cursor: pointer!important;
    padding-left: 30px;
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .user-item.selected {
    background-color: #ddd;
  }
  
  .pdf-viewer {
    align-items: center;
    margin-top: 50px;
    padding: 20px;
  }
  
  .pdf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .issue-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  .issue-button:hover {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pdf-container {
    margin-bottom: 20px;
  }
  
  .no-user {
    padding: 20px;
    font-style: italic;
    color: #777;
  }
  
  @media (max-width: 768px) {
    .admin-container {
      flex-direction: column;
    }
  
    .sidebar {
      flex: 0 0 auto;
      width: 100%;
    }
  
    .content {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  